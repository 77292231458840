import React from "react";
import { Form, Input, Button, Card, Alert } from "antd";
import { db } from "./firebase";
import moment from "moment";

export default function App() {
  const [certificate, setCertificate] = React.useState(null);
  const [form] = Form.useForm();

  const handleSubmit = ({ code }) => {
    db.collection("certificates")
      .where("identifier", "==", code)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          setCertificate(
            snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))[0]
          );
        } else {
          setCertificate(false);
        }
      });
  };

  const convertToDate = (time) => {
    return moment(new Date(time.seconds * 1000)).format("DD/MM/YYYY");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div style={{ width: 600, padding: "0 24px" }}>
        {certificate === false && (
          <Alert
            message="Nenhum certificado encontrado com o código informado."
            type="error"
            closable
          />
        )}
        <Form
          layout="vertical"
          form={form}
          initialValues={{ code: "" }}
          onFinish={handleSubmit}
          size="large"
        >
          <Form.Item
            label="Código de validação"
            name="code"
            rules={[
              {
                required: true,
                message: "Por favor, informe o código de validação",
              },
            ]}
          >
            <Input placeholder="Digite o código de validação" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Validar certificado
            </Button>
          </Form.Item>
        </Form>
        {certificate && (
          <Card title="Este certificado é válido." style={{ width: "100%" }}>
            <p>
              <strong>Nome:</strong> {certificate.client.name}
            </p>
            <p>
              <strong>Evento:</strong> {certificate.event.name}
            </p>
            <p>
              <strong>Data do evento:</strong>{" "}
              {convertToDate(certificate.event.date)}
            </p>
            <p>
              <strong>Carga horária:</strong> {certificate.event.workload} horas
            </p>
          </Card>
        )}
      </div>
    </div>
  );
}
