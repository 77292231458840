import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCysw0SMF-9UVzHzoXsB2n5lJU2XcIMbhc",
  authDomain: "tecnogis-b50cd.firebaseapp.com",
  databaseURL: "https://tecnogis-b50cd.firebaseio.com",
  projectId: "tecnogis-b50cd",
  storageBucket: "tecnogis-b50cd.appspot.com",
  messagingSenderId: "801735161019",
  appId: "1:801735161019:web:e799f74999802f0381659e",
  measurementId: "G-D1S10FY74E"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore(firebaseApp);
